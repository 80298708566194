import PropTypes from "prop-types";
import React, {useRef, useState} from "react";

import {Alert, Button, Card, CardBody, Col, Container, Input, InputGroup, Label, Row} from "reactstrap";

//redux
import {Link, useLocation, useNavigate} from "react-router-dom";
import withRouter from "../../hooks/withRouter";

// Formik validation
import profile from "assets/images/techLabLogo.png";
import companyLogo from "assets/images/companyLogo.png";
import logo from "assets/images/logo.svg";

import ReCAPTCHA from "react-google-recaptcha";
import {withTranslation} from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {post} from "../../helpers/api_helper";

const Login = props => {
    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    //meta title
    document.title = `CRM | ${props.t("Login")}`;

    const {
        setAuthUser,
        setTermsContract,
        setPrivacyContract,
        setPasswordChangeRequired, setNavigateGuard, setLoading, setDashboard, dashboard
    } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();

    const initialReq = {
        username: "",
        password: ""
    }

    const [showPassword, setShowPassword] = useState(false);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
    const [error, setError] = useState();
    const [loginReq, setLoginReq] = useState(initialReq);

    const handleChange = (e) => {
        setLoginReq({
            ...loginReq,
            [e.target.name]: e.target.value
        })
    };

    const onSubmit = async () => {
        try {
            const response = await post(`${process.env.REACT_APP_API_URL}/auth/signin`, loginReq);
            localStorage.setItem("authUser", JSON.stringify(response))
            setAuthUser(response);
            let filteredDashboard = response.role.screenList.filter((screen) => screen.screenUrl === "/doctor-dashboard" || screen.screenUrl === "/dashboard" || screen.screenUrl === "/admin-dashboard")[0];
            setLoading(true);
            if (filteredDashboard === undefined) { // screenActionlar tanımlı değilken login olunabilsin diye geliştirildi
                if (response.role.name === "ROLE_MODERATOR" || response.role.name === "ROLE_ADMIN") {
                    localStorage.setItem("dashboard", "/admin-dashboard");
                    filteredDashboard = Object.assign({},{screenUrl: "/admin-dashboard"})
                    setDashboard(filteredDashboard)
                } else if (response.role.name === "ROLE_DOCTOR") {
                    localStorage.setItem("dashboard", "/doctor-dashboard");
                    filteredDashboard = Object.assign({},{screenUrl: "/doctor-dashboard"})
                    setDashboard(filteredDashboard)
                } else {
                    localStorage.setItem("dashboard", "/dashboard");
                    filteredDashboard = Object.assign({},{screenUrl: "/dashboard"})
                    setDashboard(filteredDashboard)
                }
            }

            if (response.contracts.length > 0 || response.passwordChangeRequired === true) {
                setPasswordChangeRequired(response.passwordChangeRequired);
                setTermsContract(response.contracts && response.contracts[response.contracts.findIndex(contract => contract.contractType === "TERMS_CONDITIONS")]);
                setPrivacyContract(response.contracts && response.contracts[response.contracts.findIndex(contract => contract.contractType === "PRIVACY_POLICY")]);
                setNavigateGuard(true);
                navigate(filteredDashboard.screenUrl, {replace: true});
            } else {
                navigate(filteredDashboard.screenUrl);
            }
        } catch (err) {
            console.log("error", err)
            if (err.status === 401) {
                setError(true)
                console.log("Unauthorized");
            } else {
                console.log("Login Failed");
            }
        }
    };

    function onCaptchaChange(value) {
        setIsCaptchaSuccess(true);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <p>CRM</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={companyLogo} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="#" className="auth-logo-light">
                                            <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                          />
                        </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <div form>
                                            {error ? <Alert
                                                color="danger">{props.t("Incorrect username or password")}</Alert> : null}
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Username")}</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={loginReq.username || ""}
                                                    invalid={loginReq.username === ""}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Password")}</Label>
                                                <InputGroup>
                                                    <Input
                                                        type={
                                                            showPassword ? "text" : "password"
                                                        }
                                                        name="password"
                                                        value={loginReq.password || ""}
                                                        onChange={handleChange}
                                                        invalid={loginReq.password === ""}
                                                    />
                                                    <Button
                                                        color="light"
                                                        outline
                                                        onMouseDown={() => setShowPassword(true)}
                                                        onMouseUp={() => setShowPassword(false)}>
                                                        <i className="fa fa-eye text-secondary font-size-16"/>
                                                    </Button>
                                                </InputGroup>
                                            </div>
                                            <Row className="mt-2 me-2">
                                                <Col className="col"></Col>
                                                <Col className="col">
                                                    <ReCAPTCHA
                                                        sitekey={siteKey}
                                                        onChange={onCaptchaChange}
                                                    />
                                                </Col>
                                                <Col className="col"></Col>
                                            </Row>
                                            <div className="mt-3 d-grid">
                                                <Button
                                                    disabled={!isCaptchaSuccessful}
                                                    color="primary"
                                                    outline
                                                    onClick={onSubmit}
                                                >
                                                    {props.t("Login")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-100 text-center">
                                <img src={profile} alt="" style={{height: 40, width: 120}}/>
                                <p>
                                    © {new Date().getFullYear()} {" "}
                                    <i className="mdi mdi-heart text-danger"/>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Login.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object
};

export default withRouter(withTranslation()(Login));
