import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import { Name } from "./sectionCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
  addNewSection as onAddNewSection,
  deleteSection as onDeleteSection,
  getSections as onGetSections,
  updateSection as onUpdateSection
} from "store/section/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const Section = props => {

  //meta title
  document.title = `CRM | ${props.t("Section")}`;

  const initalCont = {
    name: "",
    description: "",
  };

  const dispatch = useDispatch();
  const [role, setRole] = useState({ label: "", value: "" });
  const [deleteSection, setDeleteSection] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [section, setSection] = useState(initalCont);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [visible, setVisible] = useState({ });

  const { sections, error } = useSelector(state => ({
    sections: state.Section.sections,
    error: state.Section.error
  }));

  useEffect(() => {
    dispatch(onGetSections());
  }, []);

  const handleRefresh = () => {
    dispatch(onGetSections());
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Visible")}`,
        accessor: "visible",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.visible === false ? props.t("NO") : props.t("YES")}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  setSection(cellProps.row.original),
                    handleSectionClick(cellProps.row);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const sectionData = cellProps.row.original;
                  onClickDelete(sectionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setSection(initalCont);
      setRole({ label: "", value: "" });
      setModal(false);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const handleSectionClick = arg => {
    const section = arg.original;
    setIsEdit(true);
    setSection({
      id: section.id,
      name: section.name,
      description: section.description,
      visible: section.visible
    });
    setVisible({ label: props.t(section.visible === true ? "YES" : "NO"), value: section.visible });

    toggle();
  };

  //TODO: Arrayleri düzelt

  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = section => {
    setDeleteSection(section);
    setDeleteModal(true);
  };

  const handleDeleteSection = () => {
    dispatch(onDeleteSection(deleteSection.id));
    setDeleteModal(false);
  };

  const onChangeVisibility = (e) => {
    setVisible(e);
    setSection({
      ...section,
      ["visible"]: e.value
    });
  };

  const handleChange = (e) => {
    setSection({
      ...section,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveSection = () => {
    if (isEdit) {
      dispatch(onUpdateSection(section));
    } else {
      if (section.name === "") {
        setMessage(`${props.t("Please fill mandatory fields")}`);
        setShowNotification(true);
        setNotificationType("Warning");
      } else {
        dispatch(onAddNewSection(section));
      }
    }
    toggle();
  };

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setMessage(error.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteSection}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Sections")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={sections}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={toggle}
                    customPageSize={25}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={section.name || ""}
                              invalid={!section.name}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Description")}</Label>
                            <Input
                              name="description"
                              type="text"
                              onChange={handleChange}
                              value={section.description || ""}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Visible")}</Label>
                            <Select
                              id="visible"
                              value={visible}
                              onChange={(e) => {
                                onChangeVisibility(e);
                              }}
                              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-section"
                              onClick={saveSection}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(Section));
