import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import PropTypes from "prop-types";
import withRouter from "../../../hooks/withRouter"
import { withTranslation } from "react-i18next";
import companyLogo from "../../../assets/images/companyLogo.png";
import { map } from "lodash";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { currencies } from "../../../constants/CurrenciesEnum";
import { findSymbol } from "../../../common/commonFunctions";

const OfferTemplate = props => {
  const printRef = React.useRef();

  const [lead, setLead] = useState(props.lead);
  const [offer, setOffer] = useState(props.offerDetail);
  const [proccessContinue, setProccessContinue] = useState(false);

  // Bi boka yaramaz
  const handleDownloadPdf = async () => {
    setProccessContinue(true);
    const element = printRef.current;
    html2canvas(element, {
      width: 2480,
      height: 3508
    }).then((canvas) => {
      let imgWidth = 400;
      let pageHeight = 480;
      let imgHeight =
        ((canvas.height * imgWidth) / 2454) * 1.24;
      var heightLeft = imgHeight;

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [400, 480]
      });

      let position = 0;
      pdf.rect(0, 0, 400, 480, "F");
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${lead.firstName}-${lead.lastName}.pdf`);
    });
    setProccessContinue(false);
  };

  const handleDownloadPDFFMultiPage = async () => {
    const element = printRef.current;
    const imgWidth = element.offsetWidth; // A4 paper width in mm  --
    const imgHeight = element.offsetHeight;
    const pageHeight = element.offsetWidth * 1.24; // A4 paper height in mm

    const height = pageHeight < imgHeight ? pageHeight : pageHeight;

    html2canvas(element, {
      scale: 1
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", [element.offsetWidth, height]);
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, height);
      pdf.save("kisaDiv.pdf");

    });
  };

  const handlePrintPdf = () => {
    const element = printRef.current;

    html2canvas(element).then(async (canvasimg) => {
      let doc = new jsPDF("p", "mm",  [element.offsetWidth, element.offsetHeight]);
      let img = canvasimg.toDataURL("image/png");
      const imgProps = doc.getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfPgHt = doc.internal.pageSize.getHeight();
      const imgRatioHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, "PNG", 0, 0, pdfWidth, imgRatioHeight);
      doc.save(`${lead.firstName}-${lead.lastName}.pdf`);
    });
  };

  const handleDownloadPDFFMultiFromSinglePage = async () => {
    const element = printRef.current;

    // Calculate the image width and page height
    const imgWidth = element.offsetWidth; // A4 paper width in mm  --
    const imgHeight = element.offsetHeight;
    const pageHeight = element.offsetWidth * 1.24; // A4 paper height in mm
    let position = 0;

    // Create a canvas for each page
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [imgWidth, pageHeight]
    });

    // Iterate through the content and add it to the PDF
    for (let page = 1; position < imgHeight; page++) {
      // Set the canvas size to match the A4 page
      canvas.width = imgWidth * 1.999; // Adjust scale for higher quality
      canvas.height = pageHeight * 1.999;

      // Draw the content to the canvas
      await html2canvas(element, {
        x: 0,
        y: position,
        width: element.offsetWidth,
        height: pageHeight,
        useCORS: true // Enable Cross-Origin Resource Sharing
      }).then((data) => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(data, 0, 0, canvas.width, canvas.height);

        // Add the canvas to the PDF
        if (page > 1) {
          pdf.addPage();
        }
        pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", 0, 0, imgWidth, pageHeight);
        position += pageHeight;
      });
    }

    // Save the PDF
    pdf.save("multipage.pdf");
  };

  const handleDownloadPDFSinglePage = async () => {
    const element = printRef.current;

    // Calculate the image width and page height
    const imgWidth = element.offsetWidth; // A4 paper width in mm  --
    const imgHeight = element.offsetHeight;
    const pageHeight = element.offsetWidth * 1.24; // A4 paper height in mm
    let position = 0;

    // Create a canvas for each page
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [imgWidth, pageHeight]
    });

    // Iterate through the content and add it to the PDF
    for (let page = 1; position < imgHeight; page++) {
      // Set the canvas size to match the A4 page
      canvas.width = imgWidth; // Adjust scale for higher quality
      canvas.height = pageHeight;

      // Draw the content to the canvas
      await html2canvas(element, {
        x: 0,
        y: position,
        width: element.offsetWidth,
        height: pageHeight,
        useCORS: true // Enable Cross-Origin Resource Sharing
      }).then((data) => {
        context.drawImage(data, 0, 0, canvas.width, canvas.height);

        // Add the canvas to the PDF
        if (page > 1) {
          pdf.addPage();
        }
        pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", 0, 0, imgWidth, pageHeight);
        position += pageHeight;
      });
    }
    // Save the PDF
    pdf.save(`${lead.firstName}-${lead.lastName}.pdf`);
  };

  return (
    <div className="container">
      <div id="divToPrint" ref={printRef}>
        <Col lg="12">
          <Card>
            <CardBody>
              <React.Fragment>
                <div id="offerDiv">
                  <div className="m-3">
                    <h4 className="float-end font-size-20">
                      {props.t(lead.gender)} {lead.firstName} {lead.lastName}
                    </h4>
                    <div className="mb-4">
                      <img src={companyLogo} alt="companyLogo" height="40" />
                    </div>
                  </div>
                  <hr />
                  <Row className="mt-5">
                    {map(
                      offer.medicalOperations,
                      (item, key) => (
                        <Row>
                          <Col xs="6">
                            <address>
                              <strong>
                                <div className="text-xl-start">
                                  {props.t("Operation Info")} :
                                </div>
                              </strong>
                              <br />
                              <React.Fragment key={key}>
                                <span> <strong>{props.t("Operation")} :</strong>{item.operationDefinition.name} </span>
                                <br />
                                <span>  <strong>{props.t("Operation Date")} :  </strong>{item.operationDate ? item.operationDate.split(" ")[0] : ""}</span>
                                <br />
                                <span>  <strong>{props.t("Doctor")} :  </strong>{item.doctor.fullName}</span>
                              </React.Fragment>
                            </address>
                          </Col>
                          <Col xs="6" className="text-end">
                            <address>
                              <strong>
                                <div className="text-xl-end">
                                  {props.t("Hospitalization Info")} :
                                </div>
                              </strong>
                              <br />
                              <React.Fragment key={key}>
                                <span>  <strong>{props.t("Hospital")} :  </strong>{item.hospital.name}</span>
                                <br />
                                <span>  <strong>{props.t("Hospitalization Date Start")} :  </strong>{item.hospitalizationStartDate ? item.hospitalizationStartDate.split(" ")[0] : ""}</span>
                                <br />
                                <span>  <strong>{props.t("Hospitalization Date End")} :  </strong>{item.hospitalizationEndDate ? item.hospitalizationEndDate.split(" ")[0] : ""}</span>
                              </React.Fragment>
                            </address>
                          </Col>
                          <br />
                          <hr />
                        </Row>
                      )
                    )}
                  </Row>
                  <Row className="mt-3">
                    <div className="py-2 mt-4 text-center">
                      <h3 className="font-size-15 fw-bold">{props.t("Offer Detail")}</h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                        <tr>
                          <th style={{ width: "70px" }}>{props.t("No.")}</th>
                          <th>{props.t("Item")}</th>
                          <th className="text-end">{props.t("Amount")}</th>
                          <th className="text-end">{props.t("Tax Amount")}</th>
                          <th className="text-end">{props.t("Total Amount")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {map(
                          offer.offerItems,
                          (item, key) => (
                            <tr key={key + 1}>
                              <td>{key + 1}</td>
                              <td>{item.name}</td>
                              <td
                                className="text-end">{item.amount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                              <td
                                className="text-end">{item.taxAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                              <td
                                className="text-end">{item.totalAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td colSpan="4" className="border-0 text-end">
                            <strong>{props.t("Total")}</strong>
                          </td>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                              {offer.totalAmount} {offer.currency && findSymbol(offer.currency) || ""}
                            </h4>
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <br />
                  <hr />
                </div>
                <div id="descTempDiv" className="text-center">
                  <h4 className="font-size-18 m-3 text-dark-emphasis">
                    {offer.descriptionTemplate && offer.descriptionTemplate.name || ""}
                  </h4>
                  <React.Fragment>
                    {offer.descriptionTemplate ? (
                      <div className="m-3 font-size-16"
                           dangerouslySetInnerHTML={{ __html: offer.descriptionTemplate.description }}></div>) : ""}
                  </React.Fragment>
                </div>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </div>
      <div className="float-end">
        <Button
          onClick={handlePrintPdf}
          //onClick={handlePrintPdf}
          className="btn btn-success  me-2"
          disabled={proccessContinue}
        >
          <i className="fa fa-print" /> {props.t("Download")}
        </Button>
      </div>
    </div>
  );
};
OfferTemplate.propTypes = {
  t: PropTypes.any,
  offerDetail: PropTypes.any,
  lead: PropTypes.any,
  operationDetail: PropTypes.any,
  callbackClose: PropTypes.func
};

export default withRouter(withTranslation()(OfferTemplate));
